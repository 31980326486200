import React from 'react';
import './About.css';

export default function About() {
    return (
        <div className="container-about">
            <div className="container-about-header">
                {/* <h3>O MNIE</h3> */}
            </div>

            <div className="container-about-content">
                <div className="container-about-content-photo"></div>
                <div className="container-about-content-text">
                    <p>Manufaktura Kieliszka by Hanna Dubas to miejsce, w którym pasja do piękna spotyka się z perfekcją wykonania. Spotkanie to ma niezwykle miejsce i czas - jest to bowiem jeden z najważniejszych dni naszego życia. Każdy z nas marzy, aby dzień ślubu był tym najpiękniejszym - wspominanym z radością i wzruszeniem, a żeby tak było, musimy zadbać o wiele szczegółów.
</p>
                    <br />
                    <p>Bo to szczegóły sprawiają, że coś staje się wyjątkowe.</p>
                    <br />
                    <p>Manufaktura Kieliszka by Hanna Dubas oferuje ręcznie ozdabiane kieliszki, które uświetnią każdą uroczystość. Każdy kieliszek wykonany jest z najwyższą dokładnością, jest unikalny i jedyny. W Manufakturze Kieliszka by Hanna Dubas nie znajdziesz dwóch takich samych zestawów. Do zdobienia używam różnych materiałów - kamieni ozdobnych, brokatów, koralików TOHO oraz innych dodatków biżuteryjnych. Obecnie mam w swojej ofercie ponad 70 wzorów, które trafią w gusta nawet najbardziej wymagających klientów.</p>
                    <br />
                    <p>W pracę nad każdym z nich wkładam całe swoje serce, zależy mi, by stały się ważnym elementem uroczystości, ale również piękną pamiatką dla pary młodej.</p>
                    <br />
                    <p>Wszyscy z pewnością znamy ten moment, gdy para młoda wznosi swój pierwszy toast. Warto, aby w chwili, gdy oczy wszystkich gości zwrócone są na nich, podkreślić naszym kieliszkiem piękno tej chwili. Niech stanie się ona jeszcze bardziej wyjątkowa...</p>
                    <br />
                    <p>Indywidualnie zaprojektowany i dobrany kieliszek, stanowić może doskonałe uzupełnienie stylistyki wesela, kreacji młodych czy choćby wystroju sali. Z pewnością sprawdzi się także podczas sesji zdjęciowych. Ponadto na pewno pozostanie cudowną pamiątką dla pary młodej, która z przyjemnością wracać będzie do pięknych chwil podczas świętowania kolejnych rocznic szczęśliwego małżeństwa.</p>
                    <br />
                </div>
            </div>
            
        </div>
    );

}