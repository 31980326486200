import React from 'react';

import './Pricelist.css';

export default function PriceList() {
    return (
        <div className="container-pricelist">
            <p><span className="pricelist-name">AMANDA  </span><span>234 PLN </span></p>
            <p><span className="pricelist-name">ANGELICA </span><span>234 PLN </span></p>
            <p><span className="pricelist-name">ARIANA </span><span>221 PLN </span></p>
            <p><span className="pricelist-name">BEATRIZ </span><span>247 PLN </span></p>
            <p><span className="pricelist-name">BIAŁA JARZĘBINA </span><span>260 PLN </span></p>
            <p><span className="pricelist-name">BIAŁY ŁABĄDŹ </span><span>208 PLN </span></p>
            <p><span className="pricelist-name">BLACK & WHITE </span><span>260 PLN </span></p>
            <p><span className="pricelist-name">BLACK DIAMOND </span><span>247 PLN </span></p>
            <p><span className="pricelist-name">BLANCA </span><span>234 PLN </span></p>
            <p><span className="pricelist-name">BLUE OCEAN </span><span>286 PLN </span></p>
            <p><span className="pricelist-name">BRASIL </span><span>247 PLN </span></p>
            <p><span className="pricelist-name">BRIGHT DIAMOND </span><span>234 PLN </span></p>
            <p><span className="pricelist-name">CALIFORNIA </span><span>260 PLN</span></p>
            <p><span className="pricelist-name">CARMEN </span><span>234 PLN </span></p>
            <p><span className="pricelist-name">CELESTINA </span><span>195 PLN </span></p>
            <p><span className="pricelist-name">CELINE </span><span>208 PLN </span></p>
            <p><span className="pricelist-name">CEZAR </span><span>234 PLN </span></p>
            <p><span className="pricelist-name">CHRISTIAN </span><span>182 PLN </span></p>
            <p><span className="pricelist-name">CLARISA </span><span>286 PLN </span></p>
            <p><span className="pricelist-name">DWA SERCA </span><span>234 PLN </span></p>

            <p><span className="pricelist-name">ELIZA </span><span>208 PLN </span></p>
            <p><span className="pricelist-name">EXCLUSIVE </span><span>260 PLN </span></p>
            <p><span className="pricelist-name">GATSBY </span><span>221 PLN </span></p>
            <p><span className="pricelist-name">GOLD CUP </span><span>234 PLN </span></p>
            <p><span className="pricelist-name">GOLD LANCE </span><span>234 PLN </span></p>
            <p><span className="pricelist-name">GOLD RAIN </span><span>208 PLN </span></p>
            <p><span className="pricelist-name">KRYSTAL </span><span>299 PLN </span></p>
            <p><span className="pricelist-name">KRYSZTAŁOWY KWIAT </span><span>247 PLN </span></p>
            <p><span className="pricelist-name">KWIAT PAPROCI  </span><span>247 PLN </span></p>
            <p><span className="pricelist-name">KWIAT PUSTYNI  </span><span>208 PLN </span></p>
            <p><span className="pricelist-name">LENS </span><span>234 PLN </span></p>
            <p><span className="pricelist-name">LEŚNA ŁĄKA </span><span>208 PLN </span></p>
            <p><span className="pricelist-name">MAXIM </span><span>195 PLN </span></p>
            <p><span className="pricelist-name">MEGANE </span><span>247 PLN </span></p>
            <p><span className="pricelist-name">MICHELLE </span><span>247 PLN </span></p>
            <p><span className="pricelist-name">MONICA </span><span>156 PLN </span></p>
            <p><span className="pricelist-name">NEVA </span><span>208 PLN </span></p>
            <p><span className="pricelist-name">NEW YORK </span><span>247 PLN </span></p>
            <p><span className="pricelist-name">PARIS </span><span>234 PLN </span></p>
            <p><span className="pricelist-name">PEARL CROWN </span><span>260 PLN </span></p>
            <p><span className="pricelist-name">PERŁOWA SIEĆ </span><span>234 PLN </span></p>

            <p><span className="pricelist-name">PERŁOWE DRZEWKO </span><span>182 PLN </span></p>
            <p><span className="pricelist-name">PRETORIA </span><span>247 PLN </span></p>
            <p><span className="pricelist-name">RAYMOND </span><span>286 PLN </span></p>
            <p><span className="pricelist-name">RENATA </span><span>156 PLN </span></p>
            <p><span className="pricelist-name">ROMA </span><span>221 PLN </span></p>
            <p><span className="pricelist-name">ROMANS </span><span>260 PLN </span></p>
            <p><span className="pricelist-name">ROXANNE </span><span>247 PLN </span></p>
            <p><span className="pricelist-name">ROYAL COLLECTION </span><span>260 PLN </span></p>
            <p><span className="pricelist-name">ROYAL PINK </span><span>260 PLN </span></p>
            <p><span className="pricelist-name">RÓŻOWA FANTAZJA </span><span>195 PLN </span></p>
            <p><span className="pricelist-name">SILVER LANCE </span><span>234 PLN </span></p>
            <p><span className="pricelist-name">SREBRNA ŁEZKA </span><span>247 PLN </span></p>
            <p><span className="pricelist-name">STOKROTKA </span><span>182 PLN </span></p>
            <p><span className="pricelist-name">SYLVIA </span><span>221 PLN </span></p>
            <p><span className="pricelist-name">TOKYO </span><span>234 PLN </span></p>
            <p><span className="pricelist-name">VENICE </span><span>221 PLN </span></p>
            <p><span className="pricelist-name">VICTOR </span><span>247 PLN </span></p>
            <p><span className="pricelist-name">VIVIEN </span><span>169 PLN </span></p>
            <p><span className="pricelist-name">WHITE SNOW </span><span>195 PLN </span></p>
            <p><span className="pricelist-name">XAVIER </span><span>234 PLN </span></p>
            <p><span className="pricelist-name">ZŁOTA KROPLA </span><span>221 PLN </span></p>
            
            <p><span className="pricelist-name">ZŁOTE RUNO </span><span>234 PLN </span></p>

        </div>
    )
}