import React from 'react';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import logo from './logo2.png';
import baner from './baner.png';
import Navbar from './component/navbar/Navbar';
import Home from './component/homepage/Homepage';
import About from './component/about/About';
import Collection from './component/collection/Collection';
import Orders from './component/orders/Orders';
import Cooperation from './component/cooperation/Cooperation';
import Contact from './component/contact/Contact';
import PriceList from './component/pricelist/Pricelist';
import Events from './component/events/Events';
import './App.css';




export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <header className="App-header">
          <Link to="/">
            <div className="App-logo-container">
              <img src={logo} className="App-logo" alt="logo" />
            </div>
          </Link>
        </header>
        <div className="App-baner-container">
            <img src={baner} alt="" />
          </div>
        <content className="App-content">
          <Navbar />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/omnie">
              <About />
            </Route>
            <Route path="/kolekcja">
              <Collection />
            </Route>
            <Route path="/cennik">
              <PriceList />
            </Route>
            <Route path="/zamowienia">
              <Orders />
            </Route>
            <Route path="/wydarzenia">
              <Events />
            </Route>
            <Route path="/wspolpraca">
              <Cooperation />
            </Route>
            <Route path="/kontakt">
              <Contact />
            </Route>
          </Switch>
        </content>
      </BrowserRouter>
      <div className="footer">
        <p>&copy; Copyright 2019 Manufaktura Kieliszka. All rights reserved. </p>
        <p>created by Marcin Delert</p>
      </div>
    </div>
  );
}

