import React from 'react';
import './Homepage.css';
import homephoto from '../../homephoto.jpg'
import homephoto2 from '../../homephoto2.jpg'

export default function Home() {
    return (
        <div className="container-home">
            <div className="container-home-content">
                <p>Dzień swojego ślubu i wesela będzie w waszych wspomnieniach do końca życia. Ogólna aura szczęścia, miłości i radości zostanie uwieczniona w drobiazgu, który będzie wam ją przypominał. Zamknijcie swoje cudowne chwile w kieliszkach zdobionych specjalnie na taką okazję.  Oryginalne, efektowne, zachwycające robione ręcznie specjalnie dla Ciebie kieliszki, kupisz w naszej manufakturze.</p>
            </div>
            <div className="container-home-photo">
                <img src={homephoto} alt="" />
                <p className="headline">Niezapomnianie chwile zamknięte w cudownych kieliszkach.</p>
                <img src={homephoto2} alt="" />
            </div>
        </div>
    );
}