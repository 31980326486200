import React from 'react';
import './Contact.css';


export default function Contact() {
    return (
        <div className="container-contact">
            <div className="container-contact-header">
                {/* <h3>KONTAKT</h3> */}
            </div>
            <div className="container-contact-content">
                <p style={{fontSize: '25px', letterSpacing: '10px'}}> DANE FIRMY</p>
                <br />
                <p>Manufaktura Kieliszka Hanna Dubas</p>
                <p>05-071 Sulejówek</p>
                <p>ul. Hallera 2</p>
                <br />
                <p>NIP - 952 134 09 38</p>
                <br />
                <p> Informacje</p>
                <p>+48 600932106</p>
                <p>+48 507091676</p>
                <br />
                <p>mail: manufaktura.kieliszka@wp.pl</p>
                <br />
                <br />
                <p style={{fontSize: '25px', letterSpacing: '10px'}}>PRACOWNIA</p>
                <br />
                <p>05-071 Sulejówek</p>
                <p>ul. Hallera 2</p>
            </div>

        </div>
    );
}