import React from 'react';
import './Orders.css';


export default function Orders() {
    return (
        <div className="container-orders">
            <div className="container-orders-header">
                {/* <h3>ZAMÓWIENIA</h3> */}
            </div>
            <div className="container-orders-content">
                <p>ZE WZGLĘDU NA SPECYFIKĘ WYKONYWANYCH PRODUKTÓW BARDZO PROSZĘ
O WCZEŚNIEJSZY KONTAKT TELEFONICZNY, MAILOWY LUB FORMULARZ KONTAKTOWY</p>
            </div>
            <div className="container-orders-form">
                <form action="https://formspree.io/manufaktura.kieliszka@wp.pl" method="POST">
                    <div><label name="name">IMIĘ I NAZWISKO</label></div>
                    <div><input name="name" type="text" className="orders-form-input" /></div>

                    <div><label name="email">EMAIL</label></div>
                    <div><input name="email" type="text" className="orders-form-input"/></div>
                    
                    <div><label name="subject">TEMAT</label></div>
                    <div><input name="subject" type="text" className="orders-form-input"/></div>
                    
                    <div><label name="message">WIADOMOŚĆ</label></div>
                    <div><textarea name="message" id="" cols="30" rows="10" className="orders-form-input"></textarea></div>
                    <div><button type="submit" >WYŚLIJ</button></div>
                </form> 
            </div>
        </div>
    );
}