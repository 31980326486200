import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';


export default class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false
        };
    }

    handleToggle(e) {
        e.preventDefault();
        this.setState({
            isExpanded: !this.state.isExpanded
        });
    }

    render() {
        const { isExpanded } = this.state;

        return (
            <div className="container-navbar">
                <ul className={`collapsed ${isExpanded ? "is-expanded" : ""}`}>

                    <div className="fa-bars">
                            <FontAwesomeIcon icon={faBars} aria-hidden="true" onClick={e => this.handleToggle(e)} />
                            <a className="facebook-icon-mobile" href="https://www.facebook.com/Manufaktura-Kieliszka-115693919885638/?__tn__=%2Cd%2CP-R&eid=ARDnCVy-hxIUgpYJElKLgn7rQzW2CnoTNzUQn7d8tBbOLFxNKW6MRGt5LGxNHHY_RF6U2DkoLt116n4e" target="blank" style={{color: '#969799'}}> <FontAwesomeIcon icon={faFacebook} /></a>
                    </div>

                    <nav className="nav">
                        <Link className="navigation-link" to="/omnie"><li>O MNIE</li></Link>
                        <Link className="navigation-link" to="/kolekcja"><li>KOLEKCJA</li></Link>
                        <Link className="navigation-link" to="/cennik"><li>CENNIK</li></Link>
                        <Link className="navigation-link" to="/zamowienia"><li>ZAMÓWIENIA</li></Link>
                        <Link className="navigation-link" to="/wydarzenia"><li>WYDARZENIA</li></Link>
                        <Link className="navigation-link" to="/wspolpraca"><li>WSPÓŁPRACA</li></Link>
                        <Link className="navigation-link" to="/kontakt"><li>KONTAKT</li></Link>
                        <a className="facebook-icon" href="https://www.facebook.com/Manufaktura-Kieliszka-115693919885638/?__tn__=%2Cd%2CP-R&eid=ARDnCVy-hxIUgpYJElKLgn7rQzW2CnoTNzUQn7d8tBbOLFxNKW6MRGt5LGxNHHY_RF6U2DkoLt116n4e" target="blank"> <FontAwesomeIcon icon={faFacebook} /></a>
                    </nav>
                </ul>
            </div>
        );
    }
}