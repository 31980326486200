import React from 'react';
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";
import ReactMediumImg from 'react-medium-zoom';  //https://github.com/shahen94/react-medium-zoom
import './Collection.css';

import glass1 from '../../photos/1 ok KWIAT PUSTYNI.jpg';
import glass2 from '../../photos/2 OK KWIAT PAPROCI.jpg';
import glass3 from '../../photos/3 ok LENS.jpg';
import glass4 from '../../photos/4 ok KRYSTAL.jpg';
import glass5 from '../../photos/5 ok BIAŁA JARZĘBINA.jpg';
import glass6 from '../../photos/6 ok  MEGANE.jpg';
import glass7 from '../../photos/7 ok CEZAR.jpg';
import glass8 from '../../photos/8 ok LEŚNA ŁĄKA.jpg';
import glass9 from '../../photos/9 ok1 AMANDA.jpg';
import glass10 from '../../photos/10 ok GOLD RAIN.jpg';
import glass11 from '../../photos/11 ok GOLD CUP.jpg';
import glass12 from '../../photos/12 ok BLACK DIAMOND.jpg';
import glass13 from '../../photos/13  OK 1 ROYAL COLLECTION.jpg';
import glass14 from '../../photos/14 ok CELESTINA .jpg';
// import glass15 from '../../photos';
import glass16 from '../../photos/16 ok WHITE SNOW.jpg';
import glass17 from '../../photos/17 ok CHRISTIAN .jpg';
import glass18 from '../../photos/18 ok ROYAL PINK.jpg';
import glass19 from '../../photos/19 ok PEARL CROWN.jpg';
import glass20 from '../../photos/20 ok CELINE.jpg';
import glass21 from '../../photos/21 ok MICHELLE.jpg';
import glass22 from '../../photos/22 ok BLACK & WHITE.jpg';
import glass23 from '../../photos/23 ok BRIGHT DIAMOND.jpg';
import glass24 from '../../photos/24 ok PERŁOWE DRZEWKO.jpg';
import glass25 from '../../photos/25 ok ZŁOTE RUNO.jpg';
import glass26 from '../../photos/26 ok PRETORIA.jpg';
import glass27 from '../../photos/27 ok ROMANS.jpg';
import glass28 from '../../photos/28 ok SYLVIA.jpg';
import glass29 from '../../photos/29 ok ROXANNE.jpg';
import glass30 from '../../photos/30 ok ZŁOTA KROPLA.jpg';
import glass31 from '../../photos/31 ok STOKROTKA.jpg';
import glass32 from '../../photos/32 OK 1 ANGELICA .jpg';
import glass33 from '../../photos/33 ok KRYSZTAŁOWY KWIAT.jpg';
import glass34 from '../../photos/34 ok CARMEN .jpg';
import glass35 from '../../photos/35 ok EXCLUSIVE.jpg';
import glass36 from '../../photos/36 ok SREBRNA ŁEZKA.jpg';
import glass37 from '../../photos/37 ok XAVIER .jpg';
import glass38 from '../../photos/38 ok VENICE.jpg';
import glass39 from '../../photos/39 ok ROMA.jpg';
import glass40 from '../../photos/40 ok BIAŁY ŁABĄDŹ.jpg';
import glass41 from '../../photos/41 ok RÓŻOWA FANTAZJA.jpg';
import glass42 from '../../photos/42 ok ELIZA.jpg';
import glass43 from '../../photos/43 ok MAXIM .jpg';
import glass44 from '../../photos/44 ok BLUE OCEAN.jpg';
import glass45 from '../../photos/45 ok BLANCA.jpg';
import glass46 from '../../photos/46 ok NEW YORK.jpg';
import glass47 from '../../photos/47 ok CALIFORNIA.jpg';
import glass48 from '../../photos/48 ok RAYMOND.jpg';
import glass49 from '../../photos/49 ok PERŁOWA SIEĆ.jpg';
import glass50 from '../../photos/50 ok SILVER LANCE.jpg';
import glass51 from '../../photos/51 ok. GOLD LANCEjpg.jpg';
// import glass52 from '../../photos/';
import glass53 from '../../photos/53 ok VICTOR .jpg';
import glass54 from '../../photos/54 ok BRASIL.jpg';
import glass55 from '../../photos/55 ok TOKYO.jpg';
import glass56 from '../../photos/56 ok GATSBY.jpg';
// import glass57 from '../../photos/';
import glass58 from '../../photos/58 ok PARIS.jpg';
import glass59 from '../../photos/59 ok NEVA.jpg';
import glass60 from '../../photos/60 ok VIVIEN.jpg';
import glass61 from '../../photos/61 ok MONICA.jpg';
import glass62 from '../../photos/62 ok BLANCA.jpg';
import glass63 from '../../photos/63 ok DWA SERCA.jpg';
import glass64 from '../../photos/64 ok BEATRIZ.jpg';
import glass65 from '../../photos/65 ok RENATA.jpg';
import glass66 from '../../photos/66 ok CLARISA.jpg';
import glass67 from '../../photos/67 ok ARIANA.jpg';

export default function Collection() {

    return (
        <div className="container-collection">
            <div className="container-collection-header">
                {/* <h3>KOLEKCJA</h3> */}
            </div>
            <div className="container-collection-content">
                <p>W Manufakturze Kieliszka jest kilkadziesiąt wzorów. Każdy jest zachwycający i oryginalny. Można je dostosować do rodzaju przyjęcia weselnego, kolorystyki, stylu w jakim organizowane jest wesele lub kreacji państwa młodych.
Istnieje możliwość modyfikacji wzorów, zmiany dodatków lub kolorystyki.
Co chwila pojawiać się będą nowości.</p>
                <p>Osoby zainteresowane kupnem proszę o kontakt mailowy lub za pomocą formularza kontaktowego.</p>
            </div>
            <div className="container-collection-photo">

                <div className="container-collection-photo-element">
                    <ReactMediumImg
                        src={glass1}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Kwiat Pustyni</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass2}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Kwiat Paproci</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass3}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Lens</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass4}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Krystal</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass5}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Biała Jarzębina</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass6}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Megane</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass7}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Cezar</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass8}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Leśna Łąka</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass9}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Amanda</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass10}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Gold Rain</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass11}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Gold Cup</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass12}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Black Diamond</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass13}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Royal Collection</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass14}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Celestina</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass16}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>White Snow</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass17}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Christian</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass18}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Royal Pink</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass19}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Pearl Crown</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass20}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Celine</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass21}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Michelle</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass22}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Black & White</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass23}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Bright Diamond</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass24}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Perłowe Drzewko</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass25}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Złote Runo</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass26}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Pretoria</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass27}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Romans</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass28}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Sylvia</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass29}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Roxanne</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass30}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Złota Kropla</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass31}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Stokrotka</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass32}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Angelica</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass33}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Kryształowy Kwiat</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass34}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Carmen</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass35}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Exclusive</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass36}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Srebrna Łezka</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass37}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Xavier</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass38}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Venice</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass39}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Roma</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass40}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Biały Łabądź</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass41}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Różowa Fantazja</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass42}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Eliza</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass43}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Maxim</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass44}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Blue Ocean</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass45}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Blanca</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass46}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>New York</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass47}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>California</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass48}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Raymond</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass49}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Perłowa Sieć</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass50}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Silver Lance</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass51}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Gold Lance</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass53}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Victor</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass54}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Brasil</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass55}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Tokyo</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass56}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Gatsby</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass58}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Paris</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass59}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Neva</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass60}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Vivien</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass61}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Monica</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass62}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Blanca</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass63}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Dwa Serca</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass64}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Beatriz</p>
                </div>

                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass65}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Renata</p>
                </div>
                
                <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass66}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Clarisa</p>
                </div> 
                
                 <div className="container-collection-photo-element">
                <ReactMediumImg
                        src={glass67}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                    <p>Ariana</p>
                </div>

            </div>
            <ScrollUpButton />
        </div >
    );
}
