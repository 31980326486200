import React from 'react';
import ReactMediumImg from 'react-medium-zoom';  //https://github.com/shahen94/react-medium-zoom
import './Events.css';

import event1 from '../../events_photo/targi 1.jpg';
import event2 from '../../events_photo/targi 2.jpg';
import event3 from '../../events_photo/targi 3.jpg';
import event4 from '../../events_photo/targi 4.jpg';
import event5 from '../../events_photo/targi 5.jpg';
import event6 from '../../events_photo/targi 6.jpg';
import event7 from '../../events_photo/targi 7.jpg';
import event8 from '../../events_photo/targi 8.jpg';
import event9 from '../../events_photo/targi 9.jpg';
import event10 from '../../events_photo/photo_event1.jpg';
import event11 from '../../events_photo/photo_event2.jpg';
import event12 from '../../events_photo/photo_event3.jpg';

export default function Events() {
    return (
        <div className="container-events">
            <div className="container-events-content">
                <p>Długo przygotowywaliśmy się do targów ślubnych w PKiN które odbyły się 19
                stycznia 2020 roku. Dużo pracy, ale nasze stoisko wyglądało bardzo fajnie i
profesjonalnie. Na tych fotkach widać jak wyglądało przed otwarciem…</p>
            </div>
            <div className="container-events-photo">

                <div className="container-events-photo-element">
                    <ReactMediumImg
                        src={event1}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                </div>

                <div className="container-events-photo-element">
                    <ReactMediumImg
                        src={event2}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                </div>

                <div className="container-events-photo-element">
                    <ReactMediumImg
                        src={event3}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                </div>

                <div className="container-events-photo-element">
                    <ReactMediumImg
                        src={event5}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                </div>

                <div className="container-events-photo-element">
                    <ReactMediumImg
                        src={event9}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                </div>

                <div className="container-events-content" style={{marginTop: '40px'}}>
                    <p>A to już nasi goście, którzy zjawili się w ogromnej ilości. Wszyscy byli
zachwyceni pięknem, oryginalnością, klasą wykonania. Pochwałą nie było
końca. Serce się raduje, że mamy tak wielu wielbicieli...</p>
                </div>

                <div className="container-events-photo-element">
                    <ReactMediumImg
                        src={event4}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                </div>

                <div className="container-events-photo-element">
                    <ReactMediumImg
                        src={event6}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                </div>

                <div className="container-events-photo-element">
                    <ReactMediumImg
                        src={event10}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                </div>

                <div className="container-events-photo-element">
                    <ReactMediumImg
                        src={event8}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                </div>

                <div className="container-events-photo-element">
                    <ReactMediumImg
                        src={event11}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                </div>

                <div className="container-events-photo-element">
                    <ReactMediumImg
                        src={event7}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                </div>

                <div className="container-events-photo-element">
                    <ReactMediumImg
                        src={event12}
                        onOpen={() => console.log('Image Open')}
                        onClosed={() => console.log('Image closed')}
                    />
                </div>
            </div>
        </div>
    )
}