import React from 'react';




export default function Cooperation() {

const headerStyle = {
    fontSize: '19px'
}

    const coopStyle = {
        fontWeight: 'bold',
        fontSize: '20px'
    }

    return (
        <div className="container-cooperation">
            <div className="container-cooperation-header">
                {/* <h3>WSPÓŁPRACA</h3> */}
            </div>
            <div className="container-cooperation-content">
                <div className="container-cooperation-content-text">
                    <p style={headerStyle}>Chętnie nawiąże współpracę z</p>
                    <br />
                    <p style={coopStyle}>- salonami mody ślubnej,</p>
                    <p style={coopStyle}>- wedding plannerkami,</p>
                    <p style={coopStyle}>- stylistkami,</p>
                    <p style={coopStyle}>- influencerkami,</p>
                    <p style={coopStyle}>- blogerkami</p>
                    <br/>
                    <p style={coopStyle}>oraz wszystkimi z którymi mogłabym stworzyć coś ciekawego, interesującego i wyjątkowego.</p>
                </div>
            </div>
        </div>
    );
}